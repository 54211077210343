ion-button {
  text-transform: inherit;
  min-height: 42px;
  max-height: 42px;
  font-weight: bold;
  border-radius: 8px;
  //styleName: Botão;
  font-family: Gotham;
  font-size: 0.9em;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: center;
  --border-radius: 8px;
  --border-width: 1px;
  --box-shadow: none;
}
ion-button[fill="clear"] {
  font-size: 14px;
  min-height: 2em;
  max-height: 2em;
}
ion-button[disabled] {
  --ion-color-base: var(--ion-color-medium) !important;
  opacity: 1;
}
