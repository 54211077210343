// // Gotham Normal
@font-face {
  font-family: "Gotham";
  src: url("../assets/fonts/gotham/Gotham-XLight.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("../assets/fonts/gotham/Gotham-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("../assets/fonts/gotham/Gotham-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("../assets/fonts/gotham/Gotham-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

// Gotham Italic
@font-face {
  font-family: "Gotham";
  src: url("../assets/fonts/gotham/Gotham-XLightItalic.otf") format("opentype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Gotham";
  src: url("../assets/fonts/gotham/Gotham-UltraItalic.otf") format("opentype");
  font-weight: 900;
  font-style: italic;
}
