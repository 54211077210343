//Modal Default
ion-app.web {
  ion-modal {
    --max-width: 24em !important;
    &.left-web {
      --height: 100vh;
      --border-radius: 0;
      display: flex;
      justify-content: flex-start;
    }
    &.right-web {
      --height: 100vh;
      --border-radius: 0;
      display: flex;
      justify-content: flex-end;
    }
    &.cart-content {
      --background: var(--ion-color-background);
      // background-color: var(--ion-color-background);
    }
  }
}
ion-modal {
  --height: auto;
  --min-height: 15em;
  --border-radius: 16px;
  --max-width: 20em;
  &.default {
    --max-height: 80vh;
  }
  ion-toolbar {
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --background: transparent;
    --color: white;
    height: 2.5em;
    ion-icon {
      color: var(--ion-color-dark);
      font-size: 1.8em;
    }
  }
  h1.title {
    text-align: center;
    margin: 0;
    margin-top: 4px;
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 0.5em;
    span {
      color: var(--ion-color-primary);
      font-weight: bold;
    }
  }
  .content {
    overflow-y: auto;
    background: white;
    ion-item {
      --background: white;
    }
  }
  ion-item {
    --background: white;
  }
}
